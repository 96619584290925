import ApexCharts from 'apexcharts';
import $ from "jquery";

const defaultOptions = {
    series: [],
    chart: {
        height: 350,
        type: 'line',
    },
    markers: {
        size: 5,
        colors: ['#60eddf']
    },
    colors: ['#846cfc'],
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight'
    },
    xaxis: {
        type: 'datetime',
        labels: {
            datetimeUTC: false
        }
    },
    title: {
        text: 'Followers',
        align: 'left'
    },
    tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w}) {
            var dateString = w.config.series[seriesIndex].data[dataPointIndex].x
            
            var date = new Date(Date.parse(dateString))
            if (w.config.followersData) {
                var followers = w.config.followersData.map((follower) => {
                    var followedAtDate = new Date(Date.parse(follower.followed_at + 'Z'))
                    
                    if (date.getFullYear() === followedAtDate.getFullYear() && 
                        date.getMonth() === followedAtDate.getMonth() &&
                        date.getDate() === followedAtDate.getDate() &&
                        date.getHours() === followedAtDate.getHours()
                    ) {
                        return follower
                    }
                }).filter(Boolean)
            } else {
                var followers = []
            }

            return  `<div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="row py-2 border-bottom bg-light">
                                    <div class="col-12">
                                        ${date.toLocaleString()}
                                    </div>
                                </div>
                                <div class="row py-2">
                                    <div class="col-12">
                                        <span>Followers: ${series[seriesIndex][dataPointIndex]}</span>
                                    </div>
                                </div>
                                ${RenderFollowersForToolTip(followers)}
                            </div>
                        </div>
                    </div>`
        }
    }
};

function RenderFollowersForToolTip(followers) {
    if (followers.length == 0) {
        return ''
    }

    const followersCountCutoff = 6
    var MoreFollowersHtml = ''
    if (followers.length > followersCountCutoff) {
        MoreFollowersHtml = `
            <div class='row py-1 border-top'>
                <div class='col-12 text-secondary'>
                    + ${followers.length - followersCountCutoff} more followers
                </div>
            </div>
        `
        followers = followers.slice(0, followersCountCutoff + 1)
    }

    var followersHtml = ''
    followers.forEach((follower) => followersHtml += `
        <div class="row py-2 border-top">
            <div class='col-12'>
                <div class='row'>
                    <div class='col-auto'>
                         <img class="img-fluid border rounded" style="width: 20px; height: 20px" src="${follower.profile_image_url}" />
                    </div>
                    <div class='col ps-0'>
                        ${follower.screen_name}
                    </div>
                </div>
            </div>
        </div>
    `)

    return `<div class="row">
                <div class="col-12">
                    <div class="row py-1 bg-light border-top">
                        <div class='col-12 small'>
                            <span>New Followers</span>
                        </div>
                    </div>
                    ${followersHtml}
                    ${MoreFollowersHtml}
                </div>
            </div>`
}

function TwitterAccountSnapshotsChart(resourceUri, followersUri, canvasId) {
    var chart = new ApexCharts(document.querySelector(`#${canvasId}`), defaultOptions);
    chart.render();

    EnrichChartWithData(chart, resourceUri);
    updateOptionsWithFollowersData(chart, followersUri)
}

function EnrichChartWithData(chart, resourceUri) {
    $.ajax({
        url: resourceUri,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            updateChart(chart, data);
        }
    });
}

function updateOptionsWithFollowersData(chart, followersUri) {
    $.ajax({
        url: followersUri,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
            chart.updateOptions({followersData: data})
        }
    });
}

function updateChart(chart, data) {
    chart.appendSeries({
        type: 'line',
        name: 'Followers',
        data: data.map(function(el) {
            return {
                x: new createDate(el.updated_from_twitter_at),
                y: el.followers_count
            };
        })
    });
}

function createDate(dtString) {
    return new Date(Date.parse(dtString + 'Z'));
}

export default TwitterAccountSnapshotsChart;